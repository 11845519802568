import React from 'react'
import { graphql } from 'gatsby'

import PageFactory from '@/components/PageFactory'

class IndexPage extends React.Component<any, any> {
  state = {
    isMenuOpen: false,
  }
  _menuListStateToggle = (val: boolean) => {
    this.setState({
      isMenuOpen: val,
    })
  }

  render() {
    const { page, header, footer } = this.props.data
    const { isMenuOpen } = this.state
    return (
      <PageFactory
        menuListStateToggle={this._menuListStateToggle}
        isMenuOpen={isMenuOpen}
        page={page}
        header={header}
        footer={footer}
      />
    )
  }
}

export default IndexPage

export const query = graphql`
  query LandingPage {
    page: datoCmsLandingPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      sections {
        __typename
        ...allSections
      }
    }

    header: datoCmsNavSection {
      ...NavSection
    }

    footer: datoCmsFooter {
      ...Footer
    }
  }
`
